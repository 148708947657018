<template>
  <div id="page-user-view">
    <div class="" id="account-info-col-1">
      <form>
        <div class="flex items-center justify-between mb-4">
          <h2>{{$t('Bank Deposits')}}</h2>
          <div>
            <vs-dropdown
              vs-trigger-click
              class="dropdown dd-actions cursor-pointer mr-4 "
            >
              <vs-button
                color="primary"
                icon-pack="feather"
                icon="icon-plus"
                size="large"
              >
              </vs-button>
              <vs-dropdown-menu class="add-shipment-dropdown">
                <vs-dropdown-item @click="newBankDeposit('Add')">
                  <span class="flex items-center">
                    <feather-icon
                      icon="CreditCardIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('New Bank Deposit')}}</span>
                  </span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="uploadBankDepositsSheet()">
                  <span class="flex items-center">
                    <feather-icon
                      icon="UploadIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Upload')}}</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
        <shipblu-table
          :sst="true"
          multiple
          v-model="selected"
          pagination
          :max-items="maximumItems"
          :data="bankDeposits"
          :tableLoader="tableLoader"
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-between"
          >
          </div>
          <template slot="thead">
            <shipblu-th>{{$t('ID')}}</shipblu-th>
            <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
            <shipblu-th>{{$t('Bank Reference Number')}}</shipblu-th>
            <shipblu-th>{{$t('Amount')}}</shipblu-th>
            <shipblu-th>{{$t('Deposited On')}}</shipblu-th>
            <shipblu-th>{{$t('Deposit Slip')}}</shipblu-th>
            <shipblu-th>{{$t('Statement')}}</shipblu-th>
            <shipblu-th>{{$t('Status')}}</shipblu-th>
            <shipblu-th>{{$t('Actions')}}</shipblu-th>
          </template>

          <template slot-scope="{ data }">
            <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <shipblu-td :data="data[indextr].id">
                {{ data[indextr].id }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].merchant">
                {{ data[indextr].statement ? data[indextr].statement.merchant.name : '' }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].bank_reference_number">
                {{ data[indextr].bank_reference_number }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].amount">
                {{data[indextr].amount ? (data[indextr].amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0.00}}
              </shipblu-td>

              <shipblu-td :data="data[indextr].deposit_date">
                {{ data[indextr].deposit_date }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].link_to_deposit_slip">
                <a @click="downloadBankDeposit(data[indextr])" target="_blank">{{$t('Download')}}</a>
              </shipblu-td>

              <shipblu-td :data="data[indextr].statement">
                {{ data[indextr].statement ? data[indextr].statement.number : '' }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
                <div class="m-auto flex items-center justify-center font-semibold">
                  <div><span class="status-icon mr-1"></span></div>
                  <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
                </div>
              </shipblu-td>

              <shipblu-td>
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
                  <vs-button
                    type="border"
                    size="small"
                    icon-pack="feather"
                    icon="icon-more-horizontal"
                    class="mr-2"
                  >
                  </vs-button>
                  <vs-dropdown-menu style="width: 190px">
                    <vs-dropdown-item :disabled="data[indextr].is_default" @click="newBankDeposit('Edit', data[indextr])">
                      <span class="flex items-center">
                        <feather-icon
                          icon="EditIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Edit')}}</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item :disabled="data[indextr].is_default" @click="deletePickupPoint(data[indextr])">
                      <span class="flex items-center">
                        <feather-icon
                          icon="TrashIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>{{$t('Delete')}}</span>
                      </span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </shipblu-td>
            </shipblu-tr>
          </template>
        </shipblu-table>
      </form>
    </div>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-bank-deposit :type="type" :bankDepositModal="bankDepositModal" :statements="statements" :merchants="merchants"
    @bankDepositModal="bankDepositModal = $event" :bankDeposit="bankDeposit" @loadBankDeposits="loadBankDeposits"
    ></add-bank-deposit>
    <upload-bank-deposits :file_URl="file_URl" :uploadModal="uploadModal" @uploadModal="uploadModal = $event"/>
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import AddBankDeposit from '../admin/merchant-view/AddBankDeposit.vue'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import UploadBankDeposits from './components/UploadBankDeposits.vue'
import i18nData from '../../i18n/i18nData.js'
import common from '../../assets/utils/common.js'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      editable: false,
      bankDeposits: [],
      selected: [],
      pickupPoint: '',
      deleteData: {},
      bankDepositModal: false,
      type: 'Add Pickup Point',
      bankDeposit: {
        deposit_date: '',
        bank_reference_number: '',
        number: '',
        amount: '',
        statement: {},
        merchant: {}
      },
      merchantID: '',
      totalRows: 0,
      offset: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      statements: [],
      file_URl: '',
      file_key: '',
      merchants: [],
      tableLoader: false,
      uploadModal: false
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: 'finance-accounts-bank-deposits',
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadBankDeposits()
    }
  },
  methods: {
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    uploadBankDepositsSheet () {
      clearInterval(this.closeInterval)
      this.uploadModal = true
    },
    loadStatements () {
      sendRequest(false, false, this, 'api/v1/billing/statements/?limit=100&net_cod_due=0', 'get', null, true, 
        (response) => {
          this.statements = response.data.results
        }
      )
    },
    loadMerchants () {
      sendRequest(false, false, this, 'api/v1/merchants/?limit=100', 'get', null, true, 
        (response) => {
          this.merchants = response.data.results
        }
      )
    },
    loadBankDeposits () {
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/billing/bank-deposits/${query}`, 'get', null, true, 
        (response) => {
          this.bankDeposits = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
        }
      )
    },
    downloadBankDeposit (data) {
      sendRequest(false, false, this, `api/v1/billing/bank-deposits/${data.id}/pdf/`, 'get', null, true, 
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    newBankDeposit (type, data) {
      this.type = type
      if (type === 'Add') {
        this.bankDeposit = {
          bank_reference_number: '',
          amount: '',
          deposit_slip_key: '',
          deposit_date: ''
        }
      } else {
        this.bankDeposit = data
      }
      this.bankDepositModal = true
    },
    deletePickupPoint (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `api/v1/billing/bank-deposits/${this.deleteData.id}/`, 'delete', null, true, 
        () => {
          this.loadBankDeposits()
        }
      )
    }
  },
  components: { 
    AddBankDeposit,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    UploadBankDeposits,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadStatements()
    this.loadBankDeposits()
    this.loadMerchants()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
